<template>
    <div class="add-comp pa-12">
        <h2 class="text-left">Company</h2>
        <div class="mt-4">
            <div class="my-2 d-flex align-center">
                <v-text-field v-model="cName" outlined dense label="Enter Company Name"></v-text-field>
            </div>
            <div class="d-flex align-center" v-if="addType == 'edit'">
                <span>State of Activation: </span>
                <v-radio-group v-model="activeStatus" class="activation-stat">
                    <v-radio label="Activate" :value="true"></v-radio>
                    <v-radio label="In-Active" :value="false"></v-radio>
                </v-radio-group>
            </div>
            <div>
                <h4 class="text-left">Environments</h4>
                <div>
                    <div v-for="(ev, e) in envList" :key="e" class="d-flex align-center mt-3">
                        <span class="mr-2" style="width: 85px; display: inline-block;"><b>{{ ev.name }}</b></span>
                        <v-text-field
                            v-model="ev.domain"
                            label="Domain"
                            outlined dense
                            :disabled="!ev.edit"
                            class="mr-2"
                        ></v-text-field>
                        <v-text-field
                            v-model="ev.bucket"
                            label="Bucket"
                            outlined dense
                            :disabled="!ev.edit"
                            class="mr-2"
                        ></v-text-field>
                        <v-text-field
                            v-model="ev.collection"
                            label="Collection"
                            outlined dense
                            :disabled="!ev.edit"
                            class="mr-2"
                        ></v-text-field>
                        <v-switch label="Edit" v-model="ev.edit" class="mt-0"></v-switch>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex mt-4"> 
            <v-spacer></v-spacer>
            <v-btn color="success" small rounded class="elevation-0 mr-2" @click="saveData">Save</v-btn>
            <v-btn color="error" small rounded class="elevation-0" @click="resetData">Cancel</v-btn>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                activeStatus: null,
                cName: null,
                company_id: '',
                addType: '',
                cDomain: [],
                cBucket: [],
                cCollection: [], 
                env: [],
                cEnv: null,
                dataSet: {
                    company_name: '',
                    environment: [],
                    bucket_url: {},
                    domain: {},
                    collection_ext: {}
                },
                envList: [],
                getSelectedDataset: {}
            } 
        },
        created() {
            this.getEnv()  
            this.addType = this.$route.query.type
            if (this.addType == 'edit') {
                this.getSelectedDataset = JSON.parse(localStorage.getItem('comData'))
                this.cName = this.getSelectedDataset.name
                this.cEnv = this.getSelectedDataset.domain.map(item => item.domain_name)
                this.cEnv.forEach((ev) => {
                    this.envList.push({name: ev, domain: null, bucket: null, collection: null, edit:false})
                })
                this.getSelectedDataset.domain.forEach((item) => {
                    this.envList.forEach((ev) => {
                        if (item.domain_name.toLowerCase() == ev.name.toLowerCase()) {
                            ev.domain = item.domain_value
                        }
                    })
                })
                this.getSelectedDataset.bucket_url.forEach((item) => {
                    this.envList.forEach((ev) => {
                        if (item.bucket_name.toLowerCase() == ev.name.toLowerCase()) {
                            ev.bucket = item.bucket_value
                        }
                    })
                })
                this.getSelectedDataset.collection_ext.forEach((item) => {
                    this.envList.forEach((ev) => {
                        if (item.ext_name.toLowerCase() == ev.name.toLowerCase()) {
                            ev.collection = item.ext_value
                        }
                    })
                })
                this.activeStatus = this.getSelectedDataset.is_active 
                this.company_id = this.getSelectedDataset._id 
                console.log(this.envList);
                              
            }           
        },
        methods: {
            getEnv() {
                axios({
                    url: process.env.VUE_APP_API + '/env/info?info_type=env',
                    method: 'get'
                }).then((res) => {
                    console.log('env', res.data.data);
                    this.env = res.data.data
                    if (this.addType == 'new') {
                        this.env.forEach((env) => {
                            this.envList.push({name: env, domain: null, bucket: null, collection: null, edit: false})
                        })
                        console.log('env listy => ', this.envList);
                    }
                })
            },
            saveData(){
                this.dataSet.company_name = this.cName
                this.dataSet.environment = this.cEnv
                this.dataSet.active = this.activeStatus
                console.log((this.cBucket.length == 1 && this.cBucket[0].name != null), this.cBucket.length, this.cBucket);
                
                if (this.cDomain.length == 1 && this.cDomain[0].name != null) {
                    this.cDomain.forEach((item) => {
                        this.dataSet.domain[item.name] = item.value
                    })   
                }
                if (this.cBucket.length == 1 && this.cBucket[0].name != null) {
                    this.cBucket.forEach((item) => {
                        this.dataSet.bucket_url[item.name] = item.value
                    })   
                }
                if (this.cCollection.length == 1 && this.cCollection[0].name != null) {
                    this.cCollection.forEach((item) => {
                        this.dataSet.collection_ext[item.name] = item.value
                    })   
                }
                if (this.addType == 'new') {
                    this.dataSet.environment = []
                    this.envList.forEach((ev) => {
                        if (ev.edit) {
                            this.dataSet.environment.push(ev.name)
                            this.dataSet.domain[ev.name] = ev.domain
                            this.dataSet.bucket_url[ev.name] = ev.bucket
                            this.dataSet.collection_ext[ev.name] = ev.collection
                        }
                    })
                    console.log(this.dataSet);
                    this.dataSet.company_id = ''
                    axios({
                        url: process.env.VUE_APP_API + '/single/company',
                        method: 'post',
                        data: this.dataSet
                    }).then((res) => {
                        console.log(res.data);
                        this.resetData()
                    })   
                }
                if (this.addType == 'edit') {
                    this.dataSet.company_id = this.company_id
                    this.dataSet.environment = this.getSelectedDataset.environment
                    this.envList.forEach((ev) => {
                        this.dataSet.domain[ev.name] = ev.domain
                        this.dataSet.bucket_url[ev.name] = ev.bucket
                        this.dataSet.collection_ext[ev.name] = ev.collection
                    })
                    console.log(this.dataSet);
                    axios({
                        url: process.env.VUE_APP_API + '/single/company',
                        method: 'put',
                        data: this.dataSet
                    }).then((res) => {
                        console.log(res.data);
                        this.resetData()
                    })   
                }
                
            },
            resetData(){
                this.$router.go(-1)
            },
           
        }
    }
</script>
<style lang="scss">
.activation-stat {
    margin: 0px !important;
    .v-input--radio-group__input {
        display: flex;
        align-items: center;
        flex-direction: row !important;
        .v-radio {
            margin: 0px !important;
            padding-left: 4px;
        }
    }
}
</style>