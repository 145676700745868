<template>
    <div class="company container pa-12">
        <div class="d-flex align-center justify-space-between">
            <h2>Company</h2>
            <div class="d-flex align-center">
                <v-btn color="black" small rounded class="white--text elevation-0" @click="addCompany"> <v-icon>mdi-plus</v-icon> Add Company</v-btn>
                <v-btn v-if="selectedComp.length > 0" color="success" small rounded class="elevation-0 ml-2" @click="migDialog = true;">Deploy Company Config</v-btn>
            </div>
        </div>
        <div class="company-data" v-if="!loadingComp">
            <v-card>
                <v-card-title primary-title>
                    <span>Comapny List</span>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <hr>
                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="compData"
                        class="elevation-0"
                        show-select
                        single-select
                        item-key="_id"
                        :search="search"
                        :items-per-page="15"
                        :loading="loadingComp"
                        v-model="selectedComp"
                    >
                        <!-- <template v-slot:item.domain="{item}">
                            <p class="mb-1"><b>Domain: </b>{{ item.domain.length }}</p>
                            <p class="mb-1"><b>Bucket: </b>{{ item.bucket_url.length }}</p>
                            <p class="mb-1"><b>Collection: </b>{{ item.collection_ext.length }}</p>
                        </template> -->
                        <template v-slot:item.environment="{item}">
                            <v-chip x-small class="mx-1" v-for="(env, e) in item.environment" :key="e" :color="checkEnv(env)"> {{ env }}</v-chip>
                        </template>
                        <template v-slot:item.is_active="{item}">
                            <div class="d-flex align-center" v-for="(alv, a) in item.all_env_active_status" :key="a" v-if="item.all_env_active_status.length > 0">
                                <v-chip x-small class="mx-1" style="text-transform: capitalize;" :color="checkEnv(alv.env_name)"> {{ alv.env_name }}</v-chip> : <v-chip class="ml-1" x-small :color="(alv.is_active)?'success': 'error'">{{(alv.is_active)? 'Active':'In-Active'}}</v-chip>
                            </div>
                        </template>
                        <template v-slot:item.actions="{item}">
                            <v-menu offset-y top open-on-hover>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" v-bind="attrs" v-on="on" outlined rounded x-small class="mr-2"> <v-icon size="12" class="mr-1">mdi-information</v-icon> Info</v-btn>
                                </template>
                                <div class="d-flex pa-2" style="background-color: #fff; border-radius: 8px;">
                                    <div class="mx-1">
                                        <div class="text-center font-weight-bold">
                                            Domain
                                        </div>
                                        <div v-for="(dd, d) in item.domain" :key="d" class="d-flex ma-1">
                                            <div style="width: 95px;" class="font-weight-bold text-right">
                                                {{dd.domain_name}}:
                                            </div>
                                            <div style="width: 200px; padding-left: 8px; word-break: break-all; text-decoration: underline; text-align: left;">
                                                {{ dd.domain_value }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mx-1">
                                        <div class="text-center font-weight-bold">
                                            Bucket
                                        </div>
                                        <div v-for="(bb, b) in item.bucket_url" :key="b" class="d-flex ma-1">
                                            <div style="width: 95px;" class="font-weight-bold text-right">
                                                {{bb.bucket_name}}:
                                            </div>
                                            <div style="width: 200px; padding-left: 8px; word-break: break-all; text-decoration: underline; text-align: left;">
                                                {{ bb.bucket_value }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mx-1">
                                        <div class="text-center font-weight-bold">
                                            Collection
                                        </div>
                                        <div v-for="(cc, c) in item.collection_ext" :key="c" class="d-flex ma-1">
                                            <div style="width: 95px;" class="font-weight-bold text-right">
                                                {{cc.ext_name}}:
                                            </div>
                                            <div style="width: 200px; padding-left: 8px; word-break: break-all; text-decoration: underline; text-align: left;">
                                                {{ cc.ext_value }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-menu>
                            <v-btn color="primary" outlined rounded x-small class="mr-2" @click="setValueForEdit(item)"> <v-icon size="12" class="mr-1">mdi-pencil</v-icon> Edit</v-btn>
                            <v-btn v-if="!item.is_active" color="error" elevation="0" outlined rounded x-small @click="delDialog = true;compId = item._id"> <v-icon size="12" class="mr-1">mdi-delete</v-icon> Delete</v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </div>
        <div v-if="loadingComp" class="d-flex justify-center align-center mt-12">
            <v-progress-circular
            :size="50"
            color="black"
            indeterminate
            ></v-progress-circular>
            <span class="ml-2">Loading Company Data....</span>
        </div>
        <v-dialog
            v-model="delDialog"
            persistent :overlay="true"
            max-width="500px"
            transition="dialog-transition"
        >
            <v-card>
                <v-card-title primary-title>
                    Delete Company Data
                </v-card-title>
                <v-card-text>
                    Are you sure you want to delete company data?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" elevation="0" rounded small @click="deleteComp">Yes</v-btn>
                    <v-btn color="error" elevation="0" rounded small @click="delDialog = false;compId = '';">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="migDialog"
            :overlay="true"
            max-width="500px"
            transition="dialog-transition"
        >
            <v-card>
                <v-card-title primary-title>
                    Deploy Company Config
                </v-card-title>
                <v-card-text>
                    <v-autocomplete outlined dense :items="env" v-model="cEnv" label="Select Env" multiple class="select-env ml-2"></v-autocomplete>
                    <v-textarea
                        outlined
                        v-model="remarks"
                        class="pl-2"
                        label="Enter Remarks"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" elevation="0" rounded small @click="compMig">Deploy</v-btn>
                    <v-btn color="error" elevation="0" rounded small @click="migDialog = false;compIdList = [];cEnv = []; remarks = null;selectedComp = [];">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" :timeout="5000" rounded="pill" right :color="msgCode">
            {{ msgText }}
        </v-snackbar>
    </div>
</template>
<script>
import company from '@/store/company';
import axios from 'axios'

export default {
    data() {
        return {
            selectedComp: [],
            search: '',
            remarks: '',
            migDialog: false,
            depComp: false,
            snackbar: false,
            delDialog: false,
            selectAll: false,
            compIdList: [],
            env: [],
            cEnv: [],
            compId: '',
            msgCode: '',
            msgText: '',
            loadingComp: false,
            selectedDataSet: {},
            addType: '',
            compData: [],
            loading: false,
            headers: [
                {text: 'Company', value: 'name', width: '150px'},
                {text: 'Env', value: 'environment', width: '200px'},
                {text: 'Status', value:'is_active'},
                {text: 'Actions', value: 'actions', align: 'center'},
            ],
        }
    },
    mounted() {
        this.loadingComp = true
        this.getCompanyData()
        this.getEnv()
    },
    methods: {
        getEnv() {
            axios({
                url: process.env.VUE_APP_API + '/env/info?info_type=env',
                method: 'get'
            }).then((res) => {
                console.log('env', res.data.data);
                this.env = res.data.data
            })
        },
        getCompanyData() {
            this.loading = true
            axios({
                url: process.env.VUE_APP_API + '/env/companies',
                method: 'get'
            }).then((res) => {
                this.loadingComp = false
                this.compData = res.data.data
                this.compData.forEach((item) => item.check = false)
                console.log(this.compData);
            }).catch((err) => {
                this.snackbar = true
                this.msgText = err.response.data.message
                this.msgCode = 'error'
            })
        },
        selectComp() {
            this.compIdList = []
            if (this.selectAll) {
                this.compData.forEach((item) => {
                    item.check = true
                    this.compIdList.push(item._id)
                })   
            } else {
                this.compData.forEach((item) => {
                    item.check = false
                })
                this.compIdList = []
            }
            console.log(this.compIdList);
            
        },
        singleSel(id) {
            console.log(id);
            let cnt = 0
            if (this.compIdList.length == 0) {
                console.log('zero push');
                this.compIdList.push(id)
                return;
            }
            if (this.compIdList.length > 0) {
                console.log('length push');
                
                this.compIdList.forEach((item, i) => {
                    if (item == id) {
                        cnt++
                        this.compIdList.splice(i, 1)
                    }
                })
                if (cnt == 0) {
                    console.log('not present push');
                    
                    this.compIdList.push(id)
                }
            }
            console.log(this.compIdList);
            
        },
        checkEnv(val) {
            if (val == 'Develop' || val == 'develop') {
                return 'orange darken-1'
            }
            if (val == 'QA' || val == 'qa') {
                return 'pink lighten-4'
            }
            if (val == 'Staging' || val == 'staging') {
                return 'light-blue lighten-2'
            }
            if (val == 'pilot' || val == 'pilot') {
                return  'deep-orange lighten-3'
            }
            if (val == 'Production' || val == 'production') {
                return 'green lighten-2'
            }
        },
        addCompany() {
            this.$router.push({name: 'Add Company', query: {type: 'new'}})
        },
        setValueForEdit(val) {
            console.log('clicked');
            console.log(val);
            localStorage.setItem('comData', JSON.stringify(val))
            this.$router.push({name: 'Add Company', query: {type: 'edit'}})
        },
        deleteComp() {
            console.log(this.compId);
            axios({
                url: process.env.VUE_APP_API + '/single/company?company_id=' + this.compId,
                method: 'delete',
            }).then((res) => {
                console.log(res.data);
                this.delDialog = false
                this.snackbar = true
                this.msgCode = 'success'
                this.msgText = 'Company Data Deleted'
                this.getCompanyData()
            })  
        },
        compMig() {
            console.log(this.selectedComp);
            
            let dataSet = {}
            if (this.selectedComp.length == this.compData.length) {
                dataSet = {destination_env: this.cEnv, remarks: this.remarks}
            } else {
                dataSet = {destination_env: this.cEnv, remarks: this.remarks,company_id_list: this.selectedComp.map(item => item._id) }
            }
            console.log(dataSet);
            
            axios({
                url: process.env.VUE_APP_API + '/company/migration',
                method: 'post',
                data: dataSet
            }).then((res) => {
                this.snackbar = true
                this.msgCode = 'success'
                this.msgText = 'Company Config Deployed'
                this.migDialog = false
                this.getCompanyData()
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.company {
    text-align: left;   
}
</style>