import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Company from '@/views/companies/Companies.vue'
import AddCompany from '@/views/companies/AddCompany.vue'
import Login from '@/views/auth/login'
import Acs from '@/views/auth/Acs'
import itemsListing from '@/views/items';
import rulesListing from '@/views/rules';
import sectionsListing from '@/views/sections';
import templatesListing from '@/views/templates';
import disciplinesListing from '@/views/disciplines';
import migration from '@/views/migration';
import UsageReport from '@/views/usage_report';
import history from '@/views/migration/history.vue';
import associteItem from '@/views/items/associateItem.vue';
// import { v4 as uuidv4 } from 'uuid';
// import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/acs',
    name: 'Acs',
    component: Acs
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/items',
    name: 'items',
    component: itemsListing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rules',
    name: 'rules',
    component: rulesListing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sections',
    name: 'sections',
    component: sectionsListing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/templates',
    name: 'templates',
    component: templatesListing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/migration',
    name: 'migration',
    component: migration,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/history',
    name: 'history',
    component: history,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/disciplines',
    name: 'disciplines',
    component: disciplinesListing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/associteItem',
    name: 'associte item',
    component: associteItem,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/usageReport',
    name: 'usage report',
    component: UsageReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/company',
    name: 'Company',
    component: Company,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addcompany',
    name: 'Add Company',
    component: AddCompany,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  let loggedIn = JSON.parse(localStorage.getItem('user'))
  console.log('loggedIn => ', loggedIn);
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  // console.log('requiresAuth => ',requiresAuth);
  if (requiresAuth && !loggedIn) {
    // sessionStorage.setItem('pathToLoadAfterLogin', window.location.pathname + window.location.search)
    let loginPath = window.location.pathname + window.location.search
    // console.log('loginPath', loginPath)
    localStorage.setItem('pervloginPath', loginPath)
    // next({ path: '/login'})
    next({ name: 'Login', query: { from: loginPath } });
  } else if (!requiresAuth && loggedIn) {
    next('home')
    document.getElementsByTagName('html')[0].classList.remove('overflow-y-hidden');
  } else {
    next();
  }
})

export default router
